<template>
  <div class="mainform">
    <div class="form">
      <el-form ref="form" :model="formData" :rules="formRules" :class="{disabled:formDisabled}" :disabled="formDisabled"
        label-width="100px">
        <div v-if="fieldShow.contact!==false" class="col col8">
          <el-form-item label="联系人" prop="contact">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
        </div>
        <div v-if="fieldShow.phone!==false" class="col col8">
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="formData.phone"></el-input>
          </el-form-item>
        </div>
        <div v-if="fieldShow.mail!==false" class="col col8">
          <el-form-item label="邮箱" prop="mail">
            <el-input v-model="formData.mail"></el-input>
          </el-form-item>
        </div>
        <div v-if="fieldShow.verifyCode!==false" class="col col8">
          <el-form-item label="验证码" prop="verifyCode">
            <el-input v-model="formData.verifyCode">
              <template #append>
                <el-button @click="getVerCode" v-if="time<=0" type="text" size="mini">获取验证码</el-button>
                <span v-if="time>0">{{time}}秒</span>
              </template>
            </el-input>
          </el-form-item>
        </div>
        <div v-if="fieldShow.oldPassword!==false" class="col col8">
          <el-form-item label="旧密码" prop="oldPassword">
            <el-input v-model="formData.oldPassword"></el-input>
          </el-form-item>
        </div>
        <div v-if="fieldShow.newPassword!==false" class="col col8">
          <el-form-item label="新密码" prop="newPassword">
            <el-input type="password" v-model="formData.newPassword" show-password></el-input>
          </el-form-item>
        </div>
        <div v-if="fieldShow.verifyPassword!==false" class="col col8">
          <el-form-item label="确认密码" prop="verifyPassword">
            <el-input type="password" v-model="formData.verifyPassword" show-password></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
export default {
  name: 'ComAccountDialogBody',
  props: ['fieldshow', 'form', 'dialogtype'],
  data () {
    var verifyPass = (rule, value, callback) => {
      if (value !== this.formData.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      formData: {},
      formDisabled: false,
      fieldShow: this.fieldshow,
      time: 0,
      formRules: {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, message: '密码必须为大于六位', trigger: 'blur' },
          { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{0,32}$/, message: '密码必须包含数字及字母', trigger: 'blur' }
        ],
        verifyPassword: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          { min: 6, message: '密码必须为大于六位', trigger: 'blur' },
          { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{0,32}$/, message: '密码必须包含数字及字母', trigger: 'blur' },
          { validator: verifyPass, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    getVerCode () {
      if (this.dialogtype === 2) {
        request('/api/system/user/sendMail', 'get', { mail: this.formData.mail }).then((response) => {
          if (response.code === '200') {
          }
        })
      }
      if (this.dialogtype === 3) {
        request('/api/system/user/sendSms', 'get', { phone: this.formData.phone }).then((response) => {
          if (response.code === '200') {
          }
        })
      }
      this.time = 30
      const clock = setInterval(() => {
        this.time = this.time - 1
        if (this.time <= 0) {
          clearInterval(clock)
        }
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
      .form {
        .el-form{
          :deep(.el-form-item){
                .el-form-item__content{
                    .el-input {
                        .el-input-group__append{
                            line-height: 32px;
                            padding: 0px 25px;
                            font-size: 13px;
                        }
                    }
                }
          }
        }
      }
</style>
